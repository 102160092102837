import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Raising $270 Million: A Titan Success Story';
    var slug = 'raising-270-million';

    return (
      <Layout headerStyle="black" locationInfo={this.props.location} issue="spring-summer-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2023 ${slug}`} />
          <meta http-equiv="refresh" content="0;https://campaign.fullerton.edu/about" />
        </Helmet>
        <article className="wrap small" style={{padding: '20vh 0 20vh 0'}}>
          
          <p>If you are not automaticlly redirected, click this link: <a href="https://campaign.fullerton.edu/about">https://campaign.fullerton.edu/about</a></p>

        </article>
      </Layout>
    );
  }
} 